.detail-card {
  top: 1rem;
  position: relative;
}

.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .title-content-crm {
    font-weight: 700;
    font-size: 24px;
    color: #828080;
    margin-top: 6rem;
  }
}
