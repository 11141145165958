.modal-nomor-resi {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 30px 100px;

  .title-modal {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }

  .flex-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;

    .desc-content {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.5);
      margin-top: 3px;
    }

    input {
      margin-left: 8px;
      width: 80%;

      height: 47px;
      background: #FFFFFF;
      border: 1px solid #D93025;
      border-radius: 5px;
    }
  }

  .btn-save {
    padding: 5px 10px;
    background: #242E4C;
    border-radius: 5px;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-top: 40px;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;

    &:hover {
      background-color: #d9d9d9;
      color: grey;
    }

    &:active {
      background: #242E4C;
      color: #ffffff;
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px !important;
}
