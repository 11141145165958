.mat-mdc-snack-bar-container.snackbar-success .mdc-snackbar__surface {
  background-color: rgba(24, 206, 15, 0.8);
  color: #ffffff;
  text-align: center;
}

.mat-mdc-snack-bar-container.snackbar-info .mdc-snackbar__surface {
  background-color: rgba(44, 168, 255, 0.8);
  color: #ffffff;
  text-align: center;
}

.mat-mdc-snack-bar-container.snackbar-warning .mdc-snackbar__surface {
  background-color: rgba(255, 230, 0, 0.8);
  color: #ffffff;
  text-align: center;
}

.mat-mdc-snack-bar-container.snackbar-danger .mdc-snackbar__surface {
  background-color: rgba(255, 0, 0, 0.8);
  color: #ffffff;
  text-align: center;
}
