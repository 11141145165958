.error-margin {
  margin-bottom: 30px;
}

.card-dokter {
  margin-top: 0px;
  margin-left: -50px;
  margin-right: -50px;

  .card-dokter-contents {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    justify-items: center;

    .cards {
      display: flex;
      flex-direction: row;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 15%);
      border-radius: 15px;
      padding: 17px;
      width: 80%;
      margin-top: 3rem;

      .img-dokter {
        width: 185px;
        height: 170px;
        object-fit: cover;
      }

      .content-card {
        margin-left: 18px;
        width: 100%;

        .main-title {
          width: 100%;
          min-width: 14rem;

          .name-dokter {
            font-weight: 600;
            font-size: 15px;
            color: #d35662;
            // width: 160px;
            white-space: pre-wrap;
          }
        }

        .birth,
        .address,
        .email,
        .cabang,
        .people {
          font-weight: 600;
          font-size: 10px;
          color: rgba(0, 0, 0, 0.7);
          margin-top: 7px;

          .mat-icon {
            font-size: 15px;
            margin-bottom: -1rem;
          }
        }
      }

      .action-doctor-list {
        font-size: 25px;
        position: relative;
        bottom: 1rem;
        cursor: pointer;
        left: 0.3rem;
      }

      .list-action-doctor {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 40%);
        border-radius: 5px;
        width: 9rem;
        position: absolute;
        margin-left: 11rem;
        margin-top: 1.5rem;

        .action-list {
          padding: 13px;
          cursor: pointer;

          .icon-edit-delete {
            margin-right: 0.5rem;
          }

          &:hover {
            background: #d9d9d9;
          }
        }
      }

      .action-content {
        display: flex;
        cursor: pointer;
        background: #ffffff;
        box-shadow: 0px 5.4px 27px rgba(0, 0, 0, 0.4);
        border-radius: 8px;
      }
    }
  }
}
