.dashboard-cabang {
  background-image: url(../../images/background-cabang.svg);
  position: absolute;
  background-size: cover;
  min-height: 100%;
  width: 100%;

  .title-dashboard-cabang {
    font-weight: 600;
    font-size: 48px;
    color: #ffffff;
    text-align: center;
    padding: 50px;
    letter-spacing: 0.3rem;
  }

  .card-cabang {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
    justify-items: center;

    .list-card {
      margin-top: 0.5rem;

      .cards-dash-cabang {
        width: 324px;
        border-radius: 15px;
        height: 284px;
      }

      .image-dash-cabang {
        height: 263px;
        width: 100%;
        border-radius: 15px;
        cursor: pointer;
      }

      .title-card-cabang {
        font-weight: 500;
        font-size: 24px !important;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
