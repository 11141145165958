/* You can add global styles to this file, and also import other style files */
* {
  font-family: "Poppins", sans-serif;
}

.centerize {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.tb {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  letter-spacing: 0.25px;

  color: #000000;
}
.th {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  letter-spacing: 0.25px;

  color: #000000;
}
