﻿/*
 *  Document   : _modals.scss
 *  Author     : RedStar Template
 *  Description: This scss file for modals style classes
 */
.modal {
  background-color: rgba(238, 238, 238, 0.41);
  //padding: 100px 0 0;
  max-height: 100%;
  width: 100%;

  .modal-header {
    border: none;
    padding: 0px;
    border-bottom: 1px solid #eee;

    .modal-title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .modal-content {
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.31) !important;
    border: none;
    padding: 10px;

    .modal-body {
      color: #777;
      padding: 15px 25px;
    }
  }

  .modal-footer {
    border: none;
    background-color: transparent;

    .btn {
      margin: 10px;
    }
  }
}

.addContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .form {
    display: flex;
    padding-top: 6px;
  }

  .mat-form-field {
    flex-grow: 1;
  }

  .modalHeader img {
    border-radius: 50%;
  }
}

.modalHeader {
  display: flex;
  align-items: flex-start;
  // justify-content: space-between;
  padding: 0px 10px 0px 10px;
  margin: 10px 10px 0px 10px;

  .modal-about {
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    margin-left: 50%;
    min-width: 150%;
    text-align: center;
  }
}

.modal-close-button {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #161d38;
  right: 10px;
  position: absolute !important;
}

@each $key, $val in $colors {
  .modal-col-#{$key} {
    background-color: $val;

    .modal-body,
    .modal-title {
      color: #fff !important;
    }

    .modal-footer {
      background-color: rgba(0, 0, 0, 0.12);

      .btn-link {
        color: #fff !important;

        &:hover,
        &:active,
        &:focus {
          background-color: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1052 !important;
}

.mat-dialog-content {
  margin: 0px !important;
  padding: 0px !important;
}

.dropdown-cabang {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  height: 47px;
  padding: 12px 10px;
  margin-bottom: 1.5rem;

  .mat-icon {
    float: right;
    padding: 0;
  }
}

.list-cabang {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  margin-top: 5px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  max-height: 22vh;
  overflow-y: auto;

  .lists {
    padding: 12px;
    cursor: pointer;

    &:hover {
      background: #d9d9d9;
      border-radius: 3px;
    }
  }
}

.content-upload {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: start;

  .text-upload {
    margin-top: 0px;
    display: flex;

    align-items: start;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin-bottom: 5px;
  }

  input.upload-file {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    height: 47px;
    padding: 8px;
  }
}
