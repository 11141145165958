.asw-material-icon {
  font-size: 17px;
  line-height: 29px;
  margin-top: 0;
  position: absolute;
  width: 100%;
  transform: none;
  left: 0;
  top: 0;
  height: 100%;
}

.asw-btn {
  font-size: 24px;
  height: 30px;
  min-width: 30px;
  width: 30px;
  padding: 0;
  overflow: hidden;
  position: relative;
  line-height: 41px;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #03a9f4 !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #03a9f4 !important;
}

.mat-form-field.mat-focused .mat-form-field-outline-thick {
  color: #03a9f4 !important;
}

.has-success
  .mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.has-success .mat-form-field-empty.mat-form-field-label {
  color: #4caf50 !important;
}

.has-success.mat-focused .mat-input-underline .mat-input-ripple {
  background-color: #4caf50 !important;
}

.mat-input-element {
  caret-color: #03a9f4 !important;
}

.has-danger
  .mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.has-danger .mat-form-field-empty.mat-form-field-label {
  color: #f44336 !important;
}

.has-danger.mat-focused .mat-input-underline .mat-input-ripple {
  background-color: #f44336 !important;
}

.ng-invalid.mat-focused .mat-form-field-label {
  color: #f44336 !important;
}

.asw-mat-form-field {
  display: block !important;
  width: 100%;
}

.mat-form-field.has-success .mat-input-underline {
  background-color: #4caf50 !important;
  height: 2px;
}

.mat-form-field.has-success .mat-input-underline .mat-input-ripple {
  background-color: #4caf50 !important;
}

.mat-form-field.has-success .mat-form-field-label {
  color: #4caf50 !important;
}

.imgcrop-area {
  width: 100%;
  height: 410px;
  overflow: hidden;
  background: #e6e9ee;
}

.imgcrop-preview {
  position: relative;
  width: 100%;
  height: 200px;
  margin: 10px auto;
  background: #e6e9ee;
  text-align: center;
}

.imgcrop-preview:after {
  content: attr(data-text);
  display: block;
  position: absolute;
  height: 50%;
  text-align: center;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  color: #8394a9;
}

.imgcrop-preview > img {
  position: relative;
  z-index: 1;
  max-width: 100%;
}

.asw-original {
  display: inline-block;
  margin: 0 auto;
}

.asw-original,
.asw-circular-square {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 690px) {
  .asw-original {
    margin-bottom: 50px;
  }
}

.asw-circular-square {
  border-radius: 50%;
}

.asw-pt-10 {
  padding-top: 10px;
}

.asw-mat-icon {
  font-size: 18px !important;
  position: relative;
  top: 4px;
}

.asw-mat-error {
  font-size: 12px;
}

mat-icon {
  color: #5f6368;
  fill: #5f6368 !important;
}

.asw-icon {
  color: #fff;
  fill: #fff !important;
}

.asw-radio-group {
  display: flex;
  flex-direction: column;
}

.mat-radio-button .mat-radio-container {
  height: 16px;
  width: 16px;
}

.mat-radio-button .mat-radio-container .mat-radio-outer-circle {
  height: 16px;
  width: 16px;
  border-width: 1px;
}

.mat-radio-button .mat-radio-container .mat-radio-inner-circle {
  height: 16px;
  width: 16px;
  background-color: #03a9f4 !important;
}

.mat-radio-button .mat-radio-container .mat-radio-ripple {
  opacity: 0;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #03a9f4 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  transform: scale3d(0.65, 0.65, 1);
}

.mat-radio-button.mat-radio-disabled.mat-accent .mat-radio-outer-circle {
  border-color: #000 !important;
  opacity: 0.26;
}

.mat-radio-button.mat-radio-disabled.mat-accent .mat-radio-inner-circle {
  background-color: #000 !important;
  opacity: 0.26;
}

.mat-option .mat-pseudo-checkbox {
  border-width: 1px;
}

.mat-option .mat-pseudo-checkbox-checked {
  background: transparent !important;
  border-color: rgba(0, 0, 0, 0.54) !important;
}

.mat-option .mat-pseudo-checkbox-checked:after {
  color: #03a9f4 !important;
  stroke: #03a9f4 !important;
  top: 3.5px;
  width: 10px;
  left: 2px;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #03a9f4 !important;
}

.mat-checkbox
  .mat-checkbox-layout
  .mat-checkbox-inner-container
  .mat-checkbox-frame {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.54) !important;
}

.mat-checkbox
  .mat-checkbox-layout
  .mat-checkbox-inner-container
  .mat-checkbox-background {
  background: transparent !important;
}

.mat-checkbox
  .mat-checkbox-layout
  .mat-checkbox-inner-container
  .mat-checkbox-checkmark-path {
  background: #03a9f4 !important;
  stroke: #03a9f4 !important;
}

.mat-checkbox.mb-0 .mat-checkbox-layout {
  margin-bottom: 0;
}

.mat-checkbox.mat-checkbox-disabled
  .mat-checkbox-layout
  .mat-checkbox-inner-container
  .mat-checkbox-frame {
  opacity: 0.26;
}

.mat-checkbox.mat-checkbox-disabled
  .mat-checkbox-layout
  .mat-checkbox-inner-container
  .mat-checkbox-checkmark-path {
  background: #000 !important;
  stroke: #000 !important;
  opacity: 0.26;
}

.mat-checkbox.mat-checkbox-disabled .mat-checkbox-layout .mat-checkbox-label {
  color: inherit;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: #e1f5fe !important;
}

.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: #e1f5fe !important;
}

.mat-calendar-body-selected,
.mat-calendar-body-selected:hover {
  background-color: #03a9f4 !important;
  color: #fff !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(3, 169, 244, 0.4);
}

.mat-datepicker-toggle-active {
  color: #03a9f4 !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: #e1f5fe !important;
}

.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: #e1f5fe !important;
}

.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: #e1f5fe !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #03a9f4 !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: #e1f5fe !important;
}
