.action-book {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6rem;
  .action-book-content {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    .dropdown-book {
      margin-right: 1rem;
      .field-dropdown {
        display: flex;
        flex-direction: row;
        background: #242e4c;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        cursor: pointer;
        color: #ffffff;
        padding: 12px;

        .text-dropdown {
          color: #ffffff;
          font-weight: 500;
          font-size: 12px;
          margin-top: auto;
        }

        .mat-icon {
          margin-left: 5rem;
        }
      }
      .list-dropdown {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 40%);
        border-radius: 5px;
        width: 149px;
        margin-top: 22px;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        padding: 5px;

        .lists {
          padding: 9px 13px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);

          &:hover {
            background: #d9d9d9;
            border-radius: 3px;
          }
        }
      }
    }

    .date-book {
      .mat-date-range-input-separator {
        margin-bottom: auto;
        color: #ffffff;
        margin-right: 1.5rem;
      }
      .mat-mdc-form-field:not(.mat-form-field-no-animations)
        .mdc-floating-label {
        color: #ffffff;
      }
      .mat-label {
        color: #ffffff;
      }
      .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
        background: #242e4c;
        color: #ffffff;
        height: 45px;
        border-radius: 4px;

        input {
          color: #ffffff;
          position: relative;
          top: -0.5rem;
        }

        ::placeholder {
          font-size: 12px;
          color: #ffffff;
        }
      }
      .mat-mdc-form-field-flex {
        height: inherit;
      }
    }
  }
}
