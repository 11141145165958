.addContainer-cabang {
  width: 45rem;
  max-width: 100%;
}

.dropdown-list-cabang {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  padding: 10px 14px;
  font-size: 11px;

  .mat-icon {
    float: right;
  }
}

.list-content {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  margin-top: 5px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  max-height: 22vh;
  overflow-y: auto;
  width: fit-content;

  .lists-cabang {
    padding: 9px 13px;
    cursor: pointer;

    &:hover {
      background: #d9d9d9;
      border-radius: 3px;
    }
  }
}

.btn-plus {
  background: #242E4C !important;
  float: right;
  margin-top: 1rem;
}

.dropdown-list-cabang-cabang {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  padding: 10px 14px;
  font-size: 14px;

  .mat-icon {
    float: right;
  }
}

.input-desc {
  height: 160%;
}