@import "~ng-pick-datetime/assets/style/picker.min.css";

.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.example-h2 {
  margin: 10px;
}

.example-section {
  display: flex;
  align-content: center;
  align-items: center;
  height: 60px;
}

.example-margin {
  margin: 0 10px;
}

.example-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.example-radio-button {
  margin: 5px;
}

.advance-validation .error-msg {
  margin-top: -15px;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

.mat-radio-label {
  margin-bottom: 0px;
}

.mat-datepicker-content-touch .mat-calendar {
  width: 50vh !important;
  height: 57vh !important;
}

.mat-datepicker-toggle-default-icon {
  width: 1.4em !important;
}

.mat-form-field {
  .date-icon {
    cursor: pointer;
  }
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.54);
}

.flex-patient {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;

  .date-book {
    position: relative;
    top: 10px;
    margin: 10px;
    padding-top: 0px !important;
    display: flex;
    align-items: center;

    .mat-date-range-input-separator {
      margin-bottom: auto;
      color: #ffffff;
      margin-right: 1.5rem;
    }

    .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-floating-label {
      color: #ffffff;
    }

    .mat-label {
      color: #ffffff;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      color: #ffffff;
    }

    .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
      background: #242e4c;
      color: #ffffff;
      height: 45px;
      border-radius: 4px;
      padding-top: 0px !important;
      display: flex;
      align-items: center;

      input {
        color: #ffffff;
        position: relative;
        top: -0.5rem;
      }

      ::placeholder {
        font-size: 12px;
        color: #ffffff;
      }
    }

    .mat-mdc-form-field-flex {
      height: inherit;
    }
  }

  button.mdc-fab.mdc-fab--mini.mat-mdc-mini-fab.mat-primary.mat-mdc-button-base,
  button.mdc-fab.mdc-fab--mini.mat-mdc-mini-fab.mat-warn.mat-mdc-button-base {
    background: #242e4c !important;
  }
}
