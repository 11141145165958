.form-section-janji {
  padding: 15px;
  background-image: url(../../../assets/images/banner/bg-patient.svg);
  position: relative;
  display: flex;
  flex-direction: column;

  .header-form {
    background: #d9d9d9;
    border-radius: 5px;
    // padding: 18px 0px 18px 10px;
    height: 76px;
    position: relative;
    .header-style {
      background-image: url(../../images/bg-header.svg);
      width: 100%;
      background-repeat: no-repeat;
      height: 100px;
      left: -20px;
      top: -20px;
      position: absolute;
    }
    .child-title {
      left: 18px;
      top: 18px;
      position: absolute;
      color: #fff;
      font-size: 8px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      z-index: 100000;
    }
    .parent-title {
      left: 18px;
      bottom: 18px;
      position: absolute;
      color: #fff;
      font-size: 14px;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 700;
      z-index: 100000;
    }
  }

  .bg-pink {
    margin-top: 20px;
    border-radius: 7px 7px 7px 7px;

    position: relative;
    background: #d35662;
    width: 100%;
    height: 5rem;
    .title-form {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      color: #000;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background: #fff;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
      width: 100%;
      border-radius: 0px 0px 7px 7px;
      padding: 5px 10px 10px 10px;
      position: absolute;
      top: 5px;
      .title-form-1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
      }

      .title-form-2 {
        font-size: 8px;
        font-weight: 600;
        line-height: 25px;
      }
    }
  }

  .titles {
    font-weight: 400;
    font-size: 16px;
    color: #000000;

    .star {
      color: red;
    }
  }

  .nama {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;

    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }
  }

  .birth {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;

    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }
  }

  .alamat {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;

    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }
  }

  .phone {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;

    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }
  }

  .cabang {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;
    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }
    .dropdown-show {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      top: 0.5rem;
      position: relative;
    }

    .list-dropdown {
      width: 100%;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
      padding: 5px;
      max-height: 14vh;
      overflow: auto;

      .lists {
        padding: 9px 13px;
        cursor: pointer;

        &:hover {
          background: #d9d9d9;
          border-radius: 3px;
        }
      }
    }
  }

  .poli {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;
    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }

    .dropdown-show {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      top: 0.5rem;
      position: relative;
    }

    .list-dropdown {
      width: 100%;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
      padding: 5px;
      max-height: 14vh;
      overflow: auto;

      .lists {
        padding: 9px 13px;
        cursor: pointer;

        &:hover {
          background: #d9d9d9;
          border-radius: 3px;
        }
      }
    }
  }

  .dokter {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;
    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }

    .dropdown-show {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      top: 0.5rem;
      position: relative;
    }

    .list-dropdown {
      width: 100%;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
      padding: 5px;
      max-height: 14vh;
      overflow: auto;

      .lists {
        padding: 9px 13px;
        cursor: pointer;

        &:hover {
          background: #d9d9d9;
          border-radius: 3px;
        }
      }
    }
  }

  .time {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;
    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }

    .dropdown-show {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      top: 0.5rem;
      position: relative;
    }

    .list-dropdown {
      width: 100%;
      background-color: #ffffff;
      box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
      padding: 5px;
      max-height: 14vh;
      overflow: auto;

      .lists {
        padding: 9px 13px;
        cursor: pointer;

        &:hover {
          background: #d9d9d9;
          border-radius: 3px;
        }
      }
    }
  }

  .tanggal-kunjungan {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;

    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }
  }
  .button-sub {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .btn-submit {
    background: #242e4c;
    border-radius: 5px;
    text-align: center;
    color: #ffffff;
    width: 100px;
    padding: 10px;
    margin-bottom: 60px;

    // position: relative;
    // top: 15rem;
    cursor: pointer;
  }

  .information-objective {
    margin-top: 20px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
    border-radius: 5px;

    .mat-mdc-form-field {
      width: 100%;
      margin-bottom: -23px;
    }
  }
}
// .mati{
//   margin-bottom: 30px;
// }

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white !important;
}
