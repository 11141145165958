.card-poli {
  margin-top: 0px;
  margin-left: -50px;
  margin-right: -50px;

  .card-poli-contents {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    justify-items: center;

    .cards {
      display: flex;
      flex-direction: row;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 15%);
      border-radius: 15px;
      padding: 17px;
      width: 80%;
      margin-top: 3rem;

      .img-poli {
        width: 185px;
        height: 170px;
        object-fit: cover;
      }

      .content-card {
        margin-left: 18px;
        width: 100%;

        .main-title {
          width: 100%;
          min-width: 14rem;

          .name-poli {
            font-weight: 600;
            font-size: 15px;
            color: #d35662;
            // width: 160px;
            white-space: pre-wrap;
          }
        }

        .address {
          margin-top: 7px;
          font-weight: 600;
          font-size: 10px;
          color: rgba(0, 0, 0, 0.7);

          .mat-icon {
            font-size: 15px;
            margin-bottom: -1rem;
          }
        }

        .description {
          font-weight: 600;
          font-size: 10px;
          color: rgba(0, 0, 0, 0.7);
          margin-top: 15px;
          width: 70%;
        }
      }

      .action-poli-list {
        font-size: 25px;
        position: relative;
        bottom: 1rem;
        cursor: pointer;
        left: 0.3rem;
      }

      .list-action-poli {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 40%);
        border-radius: 5px;
        width: 11rem;
        position: absolute;
        margin-left: 11rem;
        margin-top: 1.5rem;

        .action-list {
          padding: 13px;
          cursor: pointer;

          .icon-edit-delete {
            margin-right: 0.5rem;
          }

          &:hover {
            background: #d9d9d9;
          }
        }
      }
    }
  }
}
.materialTableHeader .header-buttons-left {
  display: flex;
}
.flex-between-poli {
  display: flex;
  justify-content: space-between;
}

.section-header-1 {
  width: 100%;
  display: flex;
}

.section-header-2 {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;

  .dropdown-book {
    margin-right: 1rem;

    .field-dropdown {
      display: flex;
      flex-direction: row;
      background: #242e4c;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      cursor: pointer;
      color: #ffffff;
      padding: 10.5px;

      .text-dropdown {
        color: #ffffff;
        font-weight: 500;
        font-size: 12px;
        margin-top: 4px;
      }

      .mat-icon {
        margin-left: 5rem;
        color: #ffffff;
      }
    }

    .list-dropdown {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 40%);
      border-radius: 5px;
      width: 13.5rem;
      margin-top: 4px;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      padding: 5px;
      max-height: 38vh;
      overflow: auto;

      .lists {
        padding: 9px 13px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        &:hover {
          background: #d9d9d9;
          border-radius: 3px;
        }
      }
    }
  }
}
