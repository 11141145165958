.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: grey;
  // background-image: url(../../images/kudakmnc.png);
  background-color: #f6f6f6;

  height: 100vh;
  width: 100%;
  .position {
    position: relative;

    .login-image {
      .text {
        position: absolute;
        top: 0px;
        z-index: 1000;
        font-family: "Inter" serif;
        margin-top: 90px;
        margin-left: 61px;
        .h1 {
          font-size: 48px;
          color: #fbfbfa;
          font-weight: 700;
          line-height: 58px;
        }
        .h2 {
          color: var(--neutral-colors-gray-50, #fbfbfa);
          font-size: 20px;
          font-weight: 500;
          line-height: 58px;
        }
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .login-image img {
      display: block;
    }

    .login-image:after {
      // position: absolute;
      content: "";
      // height: 100%;
      // width: 100%;
      position: absolute;
      top: 0px;
      // bottom: 80px;
      // right: 596px;
      width: 802px;
      // width: auto !important;
      height: 738px;
      // background: linear-gradient(136deg, #D35662 0%, #BEB7B8 100%);

      background: linear-gradient(
        136deg,
        #d35662 0%,
        rgba(190, 183, 184, 0.3) 100%
      );
      border-radius: 15px;
    }
  }
  .form {
    margin-left: -62px;
    z-index: 100;
    width: 448px;
    height: 480px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #fff;
    /* Elevation/Level 3 */
    box-shadow: 2px 8px 15px 0px rgba(79, 76, 74, 0.08);
    .content {
      background-color: #ffffff;

      width: 504px;
      max-height: 644px;
      background: #ffffff;
      box-shadow: 2px 8px 30px rgba(79, 76, 74, 0.08);
      border-radius: 12px;
      margin-right: 50px;

      @media screen and (max-width: 480px) {
        width: 90%;
        margin-right: 15px;
      }
      .login-form {
        display: flex;
        flex-direction: column;
        padding: 28px;
        .logo-kmnc {
          margin-right: 50%;
          margin-left: 44%;
          margin-top: 0px;

          @media screen and (max-width: 360px) {
            margin-left: 42%;
          }
        }
        .title-login-form {
          font-weight: 500;
          font-size: 22px;
          margin-top: 24px;
        }
        .desc-login-form {
          font-weight: 400;
          font-size: 14px;
          margin-top: 8px;
          margin-bottom: 27px;
        }
        .btn-login-form {
          font-weight: 600;
          font-size: 16px !important;
          line-height: 24px;
          font-family: "Poppins";
          width: 100%;
          height: 63px;
          background: #d35662;
          border-radius: 8px;
          border-color: none;
          text-align: center;
          color: #ffffff;
          padding: 0px 16px;
          cursor: pointer;
        }

        .forgot-passwd {
          font-weight: 600;
          font-family: "Poppins";
          font-size: 16px;
          color: #d35662;
          text-align: center;
          padding: 19px;
          cursor: pointer;
          line-height: 24px;
        }

        .mdc-text-field--focused:not(.mdc-text-field--disabled)
          .mdc-floating-label {
          border-color: #d35662 !important;
        }

        .mdc-text-field--outlined {
          border-color: #d35662 !important;
        }

        input {
          &:active {
            border-color: #d35662 !important;
          }
        }
      }
    }
  }
}
