.dropdown-list-janji {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  padding: 18px 14px;
  font-size: 14px;
  height: 58px;

  .mat-icon {
    float: right;
  }
}

.readonly-wrapper {
  cursor: not-allowed;
}

.readonly-wrapper .readonly-block {
  pointer-events: none;
}

.list-content {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  margin-top: 5px;
  position: absolute;
  z-index: 1;
  padding: 5px;
  cursor: pointer;
  max-height: 22vh;
  overflow-y: auto;
  width: 39%;

  .lists-janji {
    padding: 9px 13px;
    cursor: pointer;

    &:hover {
      background: #d9d9d9;
      border-radius: 3px;
    }
  }
}
