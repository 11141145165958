.dropdown-list-dokter {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  padding: 10px 14px;
  font-size: 11px;

  .mat-icon {
    float: right;
  }
}

.list-content {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  margin-top: 5px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  max-height: 22vh;
  overflow-y: auto;

  .lists-dokter {
    padding: 9px 13px;
    cursor: pointer;

    &:hover {
      background: #d9d9d9;
      border-radius: 3px;
    }
  }
}

.btn-plus {
  background: #242E4C !important;
  float: right;
  margin-left: auto;
  margin-top: auto !important;
  margin-bottom: 2rem;
  cursor: pointer;
}

.btn-add-dokter {
  background: #242E4C;
  border-radius: 5px;
  height: 36px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  padding: 5px 34px;
  margin-top: 50px;
  cursor: pointer;
}
.flex-button-center {
  display: flex;
  justify-content: center;
}
.btn-update-patient {
  background: #242E4C;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  width: fit-content;
  padding: 10px;
  cursor: pointer;
}

.dropdown-list-dokter-cabang{
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  padding: 10px 14px;
  font-size: 14px;

  .mat-icon {
    float: right;
  }
}

.list-content-cabang-dokter{
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  padding: 5px;

  .lists-dokter {
    padding: 9px 13px;
    cursor: pointer;

    &:hover {
      background: #d9d9d9;
      border-radius: 3px;
    }
  }
}